import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
// import Faq from '../components/organisms/FAQ/Faq';
import Layout from '../components/organisms/Layout/Layout';
import Container from '../components/atoms/Container/Container';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import Seo from '../components/organisms/Seo/Seo';
import FaqChatWithUs from '../components/molecules/FaqChatWithUs/FaqChatWithUs';
import FaqContent from '../components/organisms/FaqContent/FaqContent';
import ContentfulContext from '../context/ContentfulProvider';

const ContentfulFaqs = ({ data }) => {
    const page = (data && data.faqItems) || false;
    const [pageCategory, setPageCategory] = useState();
    const [pageCategoryUrl, setPageCategoryUrl] = useState();
    // console.log(page);
    const contentful = useContext(ContentfulContext);
    
    const faqsCategories = contentful ? contentful.faqsCategories : '';
    // console.log(faqsCategories);
    // console.log(faqsCategories.edges.length);

    useEffect(() => {
      faqsCategories && faqsCategories.edges && faqsCategories.edges.forEach((item, index) => {
        // console.log(item);
        const filtered = item.node.faqItems.filter(row => row.name === page.name);
        if (filtered.length > 0) {
          setPageCategory(item.node.name);
          setPageCategoryUrl(item.node.slug.replace(/^\/|\/$/g, ''));
        }
        
      });
      
    }, [faqsCategories, page]);

    // console.log(pageCategory)
    

    
    return (
        <Layout>
          {page.name &&
            <Seo title={page.name} />
          }
            
          <Container size="medium">
              <Breadcrumbs crumbs={[{link: '/', label:'Home'},{link: '/faqs', label:'FAQs'}, {link:`/faqs/${pageCategoryUrl}/`, label:`${pageCategory}`}, {label:`${page.name}`}]}/>
              <h1 className='h4 mb-5'>{page.name}</h1>
              <FaqContent post={page} />
              {/* <Faq data={page.faqItems} /> */}
          </Container>
          <FaqChatWithUs background="true" />
        </Layout>
    );
};

ContentfulFaqs.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ContentfulFaqs;

export const query = graphql`
  query ContentfulFaqItems($id: String!) {
    faqItems: contentfulFaqItems(id: { eq: $id }) {
      id
      name
      slug
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            file {
              url
              fileName
              details {
                size
              }
            }
            id
          }
        }
      }
      updatedAt(formatString: "MMMM DD, YYYY")
    }
  }
`;
